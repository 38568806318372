/* Center the entire content and give some padding */

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');


.App {
  font-family: 'Inter', sans-serif;
  text-align: center; /* Center-align all content */
  padding: 50px 20px;
  min-height: 100vh; /* Ensure the full height of the page */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Adjust from center to flex-start for smooth flow */
  align-items: center; /* Center horizontally */
  /* background: #000; */
}

/* Style the H1 to be in the center of the page */
h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

/* Wrapper for the search input and icon */
.search-wrapper {
  position: relative;
  width: 100%;
  max-width: 500px; /* Limit the search bar width */
  margin: 0 auto;
  padding-bottom: 10px; /* Padding below to ensure stable spacing */
}

/* Style the search input field */
.search-input {
  width: 100%; /* Take up full width within the wrapper */
  padding: 12px 40px 12px 20px; /* Space on the right for the icon */
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Transition for border and shadow */
  outline: none;
  box-sizing: border-box; /* Ensures padding is included in the width */
}

/* Search icon inside the input */
.search-wrapper .fa-search {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-75%);
  color: #aaa;
  font-size: 16px;
}

/* Optional - Placeholder text style */
.search-input::placeholder {
  color: #999;
  font-style: italic;
}


.popular-searches {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  list-style: none;
  padding: 0;
  margin: 0;
}

.popular-searches h3 {
  margin: 0;
  padding: 8px;
  background: #f0f0f0;
  border-bottom: 1px solid #ccc;
}

.popular-searches ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.popular-searches li {
  padding: 8px;
  cursor: pointer;
}

.popular-searches li:hover {
  background-color: #f0f0f0;
}

/* Style the H2 to be right under the search input */
h2 {
  font-size: 1.5rem;
  margin: 10px 0 30px 0; /* Space between search input and results */
}

/* Masonry layout using columns */
.grid-container {
  column-width: 300px; /* Each column will be at least 300px wide */
  column-gap: 15px; /* Space between columns */
}

.grid-item {
  background-color: #fff;
  margin-bottom: 15px; /* Space between items vertically */
  display: inline-block; /* Ensures items flow within columns */
  width: 100%; /* Makes sure each item fits the column width */
  box-sizing: border-box;
}

.grid-item img, .grid-item video {
  width: 100%;
  height: auto;
  display: block; /* Ensures no extra space from inline elements */
}

.grid-item p {
  margin: 0; /* Removes any default margin under paragraphs */
}

.advertisers-list {
  display: flex;
  overflow-x: auto;
  margin-bottom: 20px;
  padding: 10px;
}

.advertiser-item {
  background-color: #f1f1f1;
  border: 2px solid #ddd;
  padding: 10px;
  margin: 10px;
  border-radius: 20px;
  white-space: nowrap;
}

/* Masonry layout using columns */
.grid-container {
  column-width: 300px; /* Ensures each column will be at least 300px wide */
  column-gap: 15px; /* Space between columns */
  max-width: 100%; /* Ensure grid-container spans the full width of its parent */
  margin: 0 auto; /* Center the grid */
}

/* Ensure grid items flow into columns properly */
.grid-item {
  background-color: #fff;
  margin-bottom: 15px; /* Space between items vertically */
  display: inline-block; /* Ensures items flow within columns */
  width: 100%; /* Makes sure each item fits the column width */
  box-sizing: border-box;
}

/* Ensure images/videos within the grid items are responsive */
.grid-item img, .grid-item video {
  width: 100%;
  height: auto;
  display: block; /* Prevents inline-block element spacing issues */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .grid-container {
    column-width: 150px; /* Adjust column width for smaller screens */
  }
}

@media (max-width: 576px) {
  .grid-container {
    column-width: 100%; /* Single column for very small screens */
  }
}





.glitch {
  position: relative;
  color: #000;
  font-size: 4em;
  letter-spacing: 0.4em;
  animation: glitch-skew 1s infinite linear alternate-reverse;
}
.glitch::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  left: 2px;
  text-shadow: -2px 0 #fff;
  clip: rect(44px, 450px, 56px, 0);
  animation: glitch-anim 5s infinite linear alternate-reverse;
}
.glitch::after {
  content: attr(data-text);
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%;
  height: 100%;
  left: -2px;
  text-shadow: -2px 0 #fff, 2px 2px #fff;
  clip: rect(44px, 450px, 56px, 0);
  animation: glitch-anim2 5s infinite linear alternate-reverse;
}

@keyframes glitch-anim {
  0% {
    clip: rect(23px, 9999px, 36px, 0);
    transform: skew(0.59deg);
  }
  5% {
    clip: rect(81px, 9999px, 71px, 0);
    transform: skew(0.77deg);
  }
  10% {
    clip: rect(27px, 9999px, 61px, 0);
    transform: skew(0.74deg);
  }
  15% {
    clip: rect(12px, 9999px, 57px, 0);
    transform: skew(0.07deg);
  }
  20% {
    clip: rect(21px, 9999px, 86px, 0);
    transform: skew(0.84deg);
  }
  25% {
    clip: rect(54px, 9999px, 94px, 0);
    transform: skew(0.66deg);
  }
  30% {
    clip: rect(24px, 9999px, 61px, 0);
    transform: skew(0.83deg);
  }
  35% {
    clip: rect(94px, 9999px, 22px, 0);
    transform: skew(0.49deg);
  }
  40% {
    clip: rect(73px, 9999px, 19px, 0);
    transform: skew(0.42deg);
  }
  45% {
    clip: rect(25px, 9999px, 88px, 0);
    transform: skew(0.82deg);
  }
  50% {
    clip: rect(29px, 9999px, 49px, 0);
    transform: skew(0.44deg);
  }
  55% {
    clip: rect(52px, 9999px, 12px, 0);
    transform: skew(0.42deg);
  }
  60% {
    clip: rect(6px, 9999px, 72px, 0);
    transform: skew(0.28deg);
  }
  65% {
    clip: rect(55px, 9999px, 77px, 0);
    transform: skew(0.76deg);
  }
  70% {
    clip: rect(28px, 9999px, 95px, 0);
    transform: skew(0.85deg);
  }
  75% {
    clip: rect(67px, 9999px, 95px, 0);
    transform: skew(0.23deg);
  }
  80% {
    clip: rect(53px, 9999px, 93px, 0);
    transform: skew(0.36deg);
  }
  85% {
    clip: rect(96px, 9999px, 32px, 0);
    transform: skew(0.76deg);
  }
  90% {
    clip: rect(30px, 9999px, 9px, 0);
    transform: skew(0.33deg);
  }
  95% {
    clip: rect(94px, 9999px, 54px, 0);
    transform: skew(0.74deg);
  }
}
@keyframes glitch-anim2 {
  0% {
    clip: rect(88px, 9999px, 58px, 0);
    transform: skew(0.68deg);
  }
  5% {
    clip: rect(48px, 9999px, 44px, 0);
    transform: skew(0.49deg);
  }
  10% {
    clip: rect(81px, 9999px, 21px, 0);
    transform: skew(0.75deg);
  }
  15% {
    clip: rect(47px, 9999px, 74px, 0);
    transform: skew(0.14deg);
  }
  20% {
    clip: rect(52px, 9999px, 98px, 0);
    transform: skew(0.28deg);
  }
  25% {
    clip: rect(93px, 9999px, 83px, 0);
    transform: skew(0.41deg);
  }
  30% {
    clip: rect(53px, 9999px, 90px, 0);
    transform: skew(0.86deg);
  }
  35% {
    clip: rect(89px, 9999px, 37px, 0);
    transform: skew(0.82deg);
  }
  40% {
    clip: rect(12px, 9999px, 11px, 0);
    transform: skew(0.89deg);
  }
  45% {
    clip: rect(53px, 9999px, 25px, 0);
    transform: skew(0.41deg);
  }
  50% {
    clip: rect(50px, 9999px, 5px, 0);
    transform: skew(0.71deg);
  }
  55% {
    clip: rect(95px, 9999px, 28px, 0);
    transform: skew(0.85deg);
  }
  60% {
    clip: rect(63px, 9999px, 15px, 0);
    transform: skew(0.7deg);
  }
  65% {
    clip: rect(20px, 9999px, 58px, 0);
    transform: skew(0.07deg);
  }
  70% {
    clip: rect(65px, 9999px, 99px, 0);
    transform: skew(1deg);
  }
  75% {
    clip: rect(87px, 9999px, 29px, 0);
    transform: skew(0.56deg);
  }
  80% {
    clip: rect(100px, 9999px, 21px, 0);
    transform: skew(0.18deg);
  }
  85% {
    clip: rect(53px, 9999px, 85px, 0);
    transform: skew(0.02deg);
  }
  90% {
    clip: rect(78px, 9999px, 3px, 0);
    transform: skew(0.17deg);
  }
  95% {
    clip: rect(28px, 9999px, 63px, 0);
    transform: skew(0.66deg);
  }
}
@keyframes glitch-skew {
  0% {
    transform: skew(3deg);
  }
  10% {
    transform: skew(2deg);
  }
  20% {
    transform: skew(4deg);
  }
  30% {
    transform: skew(0deg);
  }
  40% {
    transform: skew(-3deg);
  }
  50% {
    transform: skew(2deg);
  }
  60% {
    transform: skew(2deg);
  }
  70% {
    transform: skew(3deg);
  }
  80% {
    transform: skew(2deg);
  }
  90% {
    transform: skew(-4deg);
  }
}





